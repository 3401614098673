import { createSlice } from '@reduxjs/toolkit';
// utils

const initialState = {
  currentItem: null,
  // nextItem: null,
  // prevItem: null,
};

const slice = createSlice({
  name: 'gplxLicenseType',
  initialState,
  reducers: {
    setCurrentItem(state, action) {
      state.currentItem = action.payload;
      // const index = state.failedSituations.findIndex(item => item.id === action.payload.id);
      // state.prevItem = state.failedSituations?.[index - 1] || null;
      // state.nextItem = state.failedSituations?.[index + 1] || null;
    },
  },
});
// Reducer
export default slice.reducer;
// Actions
export const { setCurrentItem } = slice.actions;
