/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { findShortsAPI } from "../../../service/blog/blog.shorts.service";

const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  shorts: [],
  short: null,
  search: {
    page: 0,
    size: 12,
    value: '',
    orders: [{ order: "asc", property: 'pinNo' }],
    filterBys: { tagIds: null }
  }
};

const slice = createSlice({
  name: 'shorts',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setShorts(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.shorts = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setShortsSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
    setShort(state, action) {
      state.isLoading = false;
      state.short = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { setShortsSearch, setShort } = slice.actions;
// ----------------------------------------------------------------------

export function getShorts() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { blogShorts: { search: { tags, ...other } } } = getState();
    const resp = await findShortsAPI({ ...other, value: `%${other.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setShorts(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}