import { handleRequestBlog } from '../../utils/axios';

export const findShortsAPI = async (data) => {
  const config = {
    url: '/short-video/search',
    method: 'POST',
    data
  };
  return handleRequestBlog(config);
};

export const getBlogShortsByIdAPI = async (id) => {
  const config = {
    url: `/short-video/${id}`,
    method: 'GET'
  };
  return handleRequestBlog(config);
};
