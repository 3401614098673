import { createSlice } from '@reduxjs/toolkit';
// utils

const initialState = {
  exams: [

  ],
  flags: [],
  exam: null,
  examSituation: null,
  hint: false,
  reset: false,
};

const slice = createSlice({
  name: 'gplxExam',
  initialState,
  reducers: {
    addExams(state, action) {
      const exam = action.payload;

      const exams = state.exams.filter(item => item.id !== exam.id);
      exams.push(exam);
      exams.sort((a, b) => a.orderNo - b.orderNo);

      state.exams = exams;
    },
    removeExams(state, action) {
      const { id } = action.payload;

      const exams = state.exams.filter(item => item.id !== id);
      exams.sort((a, b) => a.orderNo - b.orderNo);

      state.exams = exams;
      state.reset = !state.reset;
    },
    setExam(state, action) {
      state.exam = action.payload;
    },
    setExamSituation(state, action) {
      state.examSituation = action.payload;
    },
    setFlags(state, action) {
      state.flags.push(action.payload);
    },
    removeFlags(state) {
      state.flags = [];
    },
    setHint(state, action) {
      state.hint = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { addExams, removeExams, setExam, setExamSituation, setHint, setFlags, removeFlags } = slice.actions;