import { createSlice } from '@reduxjs/toolkit';
import { findUsersAPI } from '../../service/gplx.user.service';


const initialState = {
  isLoading: false,
  error: null,
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  users: [],
  topUsers: [],
  user: null,
  search: {
    page: 0,
    size: 9,
    value: '',
    orders: [
      {
        order: 'asc',
        property: 'createdAt',
      },
    ],
    filterBys: {},
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUsers(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.users = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setTopUsers(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.topUsers = response.data;
      state.totalElements = response.totalElements;
      state.totalPages = response.totalPages;
      state.numberOfElements = response.numberOfElements;
    },
    setUser(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.user = response.data;
    },
    setUserSearch(state, action) {
      state.isLoading = false;
      state.search = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setUserSearch, setUser } = slice.actions;

export function getUsers() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { mediaUser: { search } } = getState();

    const resp = await findUsersAPI({ ...search, value: `%${search.value}%` });
    if (resp.code === '200')
      dispatch(slice.actions.setUsers(resp));
    else
      dispatch(slice.actions.hasError(resp));
  };
}
