import { handleRequest } from "../utils/axios";


export const findUsersAPI = async (data) => {
  const config = {
    url: '/user/search/public',
    method: 'POST',
    data
  };
  return handleRequest(config);
};


export const updateMeInfoAPI = async (data) => {
  const config = {
    url: '/user/me/info',
    method: 'PUT',
    data
  };

  return handleRequest(config);
};

export const getMeAPI = async () => {
  const config = {
    url: `/user/me`,
    method: 'GET'
  };
  return handleRequest(config);
};

export const getUserByIdAPI = async (id) => {
  const config = {
    url: `/user/${id}`,
    method: 'GET'
  };
  return handleRequest(config);
};