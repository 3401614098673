import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
import examSituationReducer from './slices/gplx.exam.slice';
import failSituationReducer from './slices/gplx.failSituation.slice';
import situationReducer from './slices/gplx.situation.slice';

import licenseTypeReducer from './slices/gplx.licenseType.slice';
import examReducer from './slices/gplx.quiz';
import mediaUserReducer from './slices/gplx.user';

import blogBookmarkReducer from './slices/blog/blog.bookmark';
import blogCommentReducer from './slices/blog/blog.comment';
import blogPostReducer from './slices/blog/blog.post';
import blogQuestionReducer from './slices/blog/blog.question';
import blogShortsReducer from './slices/blog/blog.shorts';
import blogTagReducer from './slices/blog/blog.tag';
// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: `redux-gplx-`,
  blacklist: ['error'],
};

const rootReducer = combineReducers({
  situation: persistReducer({ ...rootPersistConfig, key: 'gplx-ui-situation' }, situationReducer),
  exam: persistReducer({ ...rootPersistConfig, key: 'gplx-ui-exam' }, examReducer),
  examS: persistReducer({ ...rootPersistConfig, key: 'gplx-ui-exam-situation' }, examSituationReducer),
  failSituation: persistReducer({ ...rootPersistConfig, key: 'gplx-ui-fail-situation' }, failSituationReducer),
  licenseType: persistReducer({ ...rootPersistConfig, key: 'gplx-license' }, licenseTypeReducer),
  mediaUser: persistReducer({ ...rootPersistConfig, key: "gplx-user" }, mediaUserReducer),
  // blog
  blogBookmark: persistReducer({ ...rootPersistConfig, key: 'blog-bookmark' }, blogBookmarkReducer),
  blogPost: persistReducer({ ...rootPersistConfig, key: 'blog-post' }, blogPostReducer),
  blogComment: persistReducer({ ...rootPersistConfig, key: 'blog-comment' }, blogCommentReducer),
  blogTag: persistReducer({ ...rootPersistConfig, key: 'blog-tag' }, blogTagReducer),
  blogQuestion: persistReducer({ ...rootPersistConfig, key: 'blog-question' }, blogQuestionReducer),
  blogShorts: persistReducer({ ...rootPersistConfig, key: 'blog-shorts' }, blogShortsReducer),
});

export { rootPersistConfig, rootReducer };

