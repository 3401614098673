import { createSlice } from '@reduxjs/toolkit';
import examMap from '../../assets/examMap.json';
import licenseDetails from '../../assets/licenseDetails.json';
import reviewTopicMap from '../../assets/reviewTopicMap.json';
// utils
const generateChapter = () => {
  const arr = Array.from(reviewTopicMap, (item1) =>
    Array.from(item1, (item2) => Array.from(Array(item2.indexes.length), () => null))
  );
  const arr1 = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === arr.length - 1) {
      for (let j = 0; j < 6; j++) {
        arr1.push(arr[i]);
      }
    } else {
      arr1.push(arr[i]);
    }
  }
  return arr1;
};
const generateIsCheckChapter = () => {
  const arr = Array.from(reviewTopicMap, (item1) =>
    Array.from(item1, (item2) => Array.from(Array(item2.indexes.length), () => false))
  );
  const arr1 = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === arr.length - 1) {
      for (let j = 0; j < 6; j++) {
        arr1.push(arr[i]);
      }
    } else {
      arr1.push(arr[i]);
    }
  }
  return arr1;
};
const initialState = {
  isLoading: true,
  error: null,
  resultQuiz: [],
  quizs: [],
  licenseIndex: 0,
  licenseName: 'A1',
  totalExams: 8,
  saveQuestions: [],
  length: 19,
  timeRequire: null,
  isRandomExam: false,
  examMap,
  examIndex: 0,
  indexLicenseReal: 0,
  examReal: [],
  examResults: '',
  examStatistic: '',
  questionMap: [],
  searchResults: [],
  filterValue: '',
  filterResults: [],
  require: null,
  licenseRequirement: {
    time: 18,
    correctAnswers: 21,
  },
  incorrectQuestions: [],
  licenseAnswers: Array.from(licenseDetails, (item1) =>
    Array.from(examMap[item1.id], (item2) => Array.from(Array(item2.length), () => null))
  ),

  licenseWrongQuestions: Array.from(licenseDetails, (item1) =>
    Array.apply(null, Array(item1.totalExams)).map(() => [])
  ),
  licenseChapterResults: generateChapter(),
  licenseIsCheckChapterResults: generateIsCheckChapter(),
  licenseResultOfUser: Array.from(licenseDetails, (item1) =>
    Array.apply(null, Array(item1.totalExams)).map(() => null)
  ),
  licenseIndexReviewOfUser: Array.from(licenseDetails, (item1) =>
    Array.apply(null, Array(item1.totalExams)).map(() => false)
  ),
  listExamAnswerTrue: Array.from(licenseDetails, (item1) =>
    Array.from(examMap[item1.id], (item2) => Array.from(Array(item2.length), () => null))
  ),
  licenseResults: Array.from(licenseDetails, (item1) =>
    Array.from(examMap[item1.id], (item2) => Array.from(Array(item2.length), (_, index) => [null, index]))
  ),
  licenseStatistics: Array.from(licenseDetails, (item1) =>
    Array.from(examMap[item1.id], (item2) => ({
      correctAnswers: 0,
      incorrectAnswers: 0,
      totalQuestions: item2.length,
    }))
  ),
  examAnswers: [],
  isReviewExamDone: false,
  totalExam: 0,
  listIndexExam: [],
  listIsIndexReview: Array.apply(null, Array(8)).map(() => false),
  homeFeatures: [
    {
      id: 'SelectExam',
      title: 'THI THỬ',
      subTitle: '8 đề',
      color: '#027bff',
      icon: 'file-document-outline',
    },
    {
      id: 'SaveQuestion',
      title: 'LƯU',
      subTitle: '',
      color: '#34c759',
      icon: 'bookmark-outline',
    },
    {
      id: 'UserIncorrectQuestion',
      title: 'CÂU SAI',
      subTitle: '2 câu',
      color: '#ff3b31',
      icon: 'window-close',
    },
    {
      id: 'DefaultIncorrectQuestion',
      title: 'CÂU HAY SAI',
      subTitle: '50 câu',
      color: '#fdcc00',
      icon: 'bug-outline',
    },
    {
      title: 'MÔ PHỎNG',
      subTitle: '120 câu',
      color: '#027bff',
      icon: 'car-outline',
    },
    {
      id: 'Tips',
      title: 'MẸO',
      subTitle: '',
      color: '#34c759',
      icon: 'lightbulb-outline',
    },
    {
      id: 'SignTheory',
      title: 'BIỂN BÁO',
      subTitle: '',
      color: '#ff3b31',
      icon: 'police-badge-outline',
    },
    {
      title: 'HỎI ĐÁP',
      subTitle: 'thảo luận',
      color: '#fdcc00',
      icon: 'comment-question-outline',
    },
  ],
};

const slice = createSlice({
  name: 'gplxExam',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setQuizs(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.quizs = response.data;
    },
    setIndexLicenserReal(state, action) {
      state.indexLicenseReal = action.payload.index;
      state.examReal = state.examMap[action.payload.index][action.payload.examIndex];
      state.timeRequire = action.payload.time;
      state.require = action.payload.require;
    },
    setLicenseData: (state, action) => {
      state.licenseIndex = action.payload.licenseIndex;
      state.licenseName = action.payload.licenseName;
      state.licenseRequirement = action.payload.licenseRequirement;
      state.totalExams = action.payload.totalExams;
      state.listIsIndexReview = Array.apply(null, Array(action.payload.totalExams)).map(() => false);
      state.length = examMap[state.licenseIndex].length;
      state.saveQuestions = state.examMap[state.licenseIndex][state.length - 2];
      state.incorrectQuestions = state.examMap[state.licenseIndex][state.length - 1];
      state.homeFeatures[0].subTitle = `${state.totalExams} đề`;
      state.isLoading = false;
    },
    setExamAnswers(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.examAnswers = response;
    },
    setLicenseAnswers(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.licenseAnswers[state.licenseIndex][response.examIndex] = response.data;
      state.licenseResultOfUser[state.licenseIndex][response.examIndex - 1] = response.total;
      state.licenseIndexReviewOfUser[state.licenseIndex][response.examIndex - 1] = true;
      state.listIsIndexReview = response.isReview;
    },
    setRefreshExam(state, action) {
      const response = action.payload;
      state.licenseIndexReviewOfUser[state.licenseIndex][response.examIndex - 1] = false;
      state.licenseAnswers[state.licenseIndex][response.examIndex] = Array.apply(null, Array(response.length)).map(
        () => null
      );
    },
    setListIndexExams(state, action) {
      state.isLoading = false;
      const response = action.payload;
      state.listIndexExam = response.data;
      state.totalExam = response.total;
      state.listIsIndexReview = Array.apply(null, Array(response.total)).map(() => false);
    },
    setExamData: (state, action) => {
      state.examIndex = action.payload;
      state.questionIndex = 0;
      state.questionMap = state.examMap[state.licenseIndex][state.examIndex];
      state.searchResults = state.questionMap;
      state.examAnswers = state.licenseAnswers[state.licenseIndex][state.examIndex];
      state.examResults = state.licenseResults[state.licenseIndex][state.examIndex];
      state.examStatistic = state.licenseStatistics[state.licenseIndex][state.examIndex];
    },
    setWrongQuestion: (state, action) => {
      state.licenseWrongQuestions[state.licenseIndex][action.payload.examIndex] = action.payload.data;
    },
    setChapter: (state, action) => {
      state.licenseChapterResults[state.licenseIndex][action.payload.indexChapter][action.payload.indexQuestion] =
        action.payload.value;
      state.licenseIsCheckChapterResults[state.licenseIndex][action.payload.indexChapter][
        action.payload.indexQuestion
      ] = true;
    },
    setRandomExamData: (state, action) => {
      state.examIndex = action.payload.examIndex;
      state.questionIndex = 0;
      state.isRandomExam = true;
      state.questionMap = state.examMap[state.licenseIndex][state.examIndex];
      state.searchResults = state.questionMap;
      state.examAnswers = Array.from(Array(action.payload.length), () => null);
      state.examResults = Array.from(Array(action.payload.length), (_, index) => [null, index]);
      state.examStatistic = {
        correctAnswers: 0,
        incorrectAnswers: 0,
        totalQuestions: action.payload.length,
        isFinished: action.payload.isFinished,
      };
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setIndexLicenserReal } = slice.actions;
export function selectLicense(data) {
  return (dispatch) => {
    dispatch(slice.actions.setQuizs(data));
  };
}
export function setExamAnswer(data) {
  return (dispatch) => {
    dispatch(slice.actions.setExamAnswers(data));
  };
}
export function setLicenseAnswers(data, index, isReview, examIndex, total) {
  return (dispatch) => {
    dispatch(slice.actions.setLicenseAnswers({ data, index, isReview, examIndex, total }));
  };
}
export function setArrWrongQuestion(data, examIndex) {
  return (dispatch) => {
    dispatch(slice.actions.setWrongQuestion({ data, examIndex }));
  };
}
export function setTotalExam(data, total) {
  return (dispatch) => {
    dispatch(slice.actions.setListIndexExams({ data, total }));
  };
}

export function setLicenseData(object) {
  return (dispatch) => {
    dispatch(slice.actions.startLoading(true));
    dispatch(slice.actions.setLicenseData(object));
  };
}

export function setRandom(object) {
  return (dispatch) => {
    dispatch(slice.actions.setRandomExamData(object));
  };
}

export function setRefreshExam(examIndex, length) {
  return (dispatch) => {
    dispatch(slice.actions.setRefreshExam({ examIndex, length }));
  };
}

export function setChapterResult(indexChapter, indexQuestion, value) {
  return (dispatch) => {
    dispatch(slice.actions.setChapter({ indexChapter, indexQuestion, value }));
  };
}
