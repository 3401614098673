import {
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider, OAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup, signOut
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import {
  arrayRemove,
  arrayUnion,
  doc,
  getFirestore,
  setDoc
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { FIREBASE_API } from '../config';
import useFcmToken from '../hooks/useFcmToken';
import { getMeAPI } from '../service/gplx.user.service';

// ----------------------------------------------------------------------

export const firebaseApp = firebase.initializeApp(FIREBASE_API);
const AUTH = getAuth(firebaseApp);
const FIRESTORE = getFirestore(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithGithub: () => Promise.resolve(),
  loginWithApple: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fcmToken, notificationPermissionStatus } = useFcmToken();

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const { code, data } = await getMeAPI();

          if (code === '200') {
            saveUserTokenToDatabase();
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user: data },
            });
          } else
            logout();
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const updateProfile = async () => {
    const { code, data } = await getMeAPI();
    if (code === '200') {
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: true, user: data },
      });
    }
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(AUTH, provider);
  };

  const loginWithFaceBook = () => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(AUTH, provider);
  };

  const loginWithGithub = () => {
    const provider = new GithubAuthProvider();
    return signInWithPopup(AUTH, provider);
  };

  const loginWithApple = () => {
    const provider = new OAuthProvider('apple.com');
    return signInWithPopup(AUTH, provider);
  };

  async function saveUserTokenToDatabase() {
    try {
      if (notificationPermissionStatus === "granted" && fcmToken) {
        // Update backend (e.g. Firestore) with our token for the user
        const uid = AUTH.currentUser.uid;
        // Add the token to the users datastore
        const userRef = doc(FIRESTORE, 'users', uid);
        await setDoc(userRef, {
          "fcmTokens": arrayUnion(fcmToken)
        }, { merge: true });
      }
    } catch (e) { console.log(e); }
  }

  async function revokeUserTokenToDatabase() {
    try {
      if (notificationPermissionStatus === "granted" && fcmToken) {
        // Update backend (e.g. Firestore) with our token for the user
        const user = AUTH.currentUser;
        if (user) {
          const userRef = doc(FIRESTORE, 'users', user.uid);
          await setDoc(userRef, {
            "fcmTokens": arrayRemove(fcmToken)
          }, { merge: true });
        }
      }
    } catch (e) { console.log(e); }
  }

  const logout = async () => {
    await revokeUserTokenToDatabase();
    signOut(AUTH);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        loginWithGoogle,
        loginWithFaceBook,
        loginWithApple,
        loginWithGithub,
        logout,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

