import { createSlice } from '@reduxjs/toolkit';
// utils

const initialState = {
  currentItem: null,
  nextItem: null,
  prevItem: null,
  failedSituations: []
};

const slice = createSlice({
  name: 'gplxFailSituation',
  initialState,
  reducers: {
    setFailedSituations(state, action) {
      const failedSituations = state.failedSituations.filter(item => item.id !== action.payload.id);

      const situations = [action.payload, ...failedSituations];

      if (situations.length > 50)
        situations.splice(50, 1);

      state.failedSituations = situations;
    },
    setCurrentItem(state, action) {
      state.currentItem = action.payload;
      const index = state.failedSituations.findIndex(item => item.id === action.payload.id);
      state.prevItem = state.failedSituations?.[index - 1] || null;
      state.nextItem = state.failedSituations?.[index + 1] || null;
    },
    removeFailedPass(state) {
      const failedSituations = state.failedSituations.filter(item => item.score === 0);
      state.failedSituations = failedSituations;
      state.currentItem = failedSituations?.[0];
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setFailedSituations, setCurrentItem, removeFailedPass } = slice.actions;