// i18n
import '../locales/i18n';

// highlight
import '../utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'video.js/dist/video-js.css';
import 'videojs-http-source-selector/dist/videojs-http-source-selector.css';
// City
import '@videojs/themes/dist/city/index.css';
import '../../styles/customVideoJS.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import cookie from 'cookie';
import PropTypes from 'prop-types';
// next
import App from 'next/app';
//
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
import { store } from '../redux/store';
// utils
import { getSettings } from '../utils/settings';
// contexts
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
import { SettingsProvider } from '../contexts/SettingsContext';
// theme
import ThemeProvider from '../theme';
// components
import { Icon } from '@iconify/react';
import NotistackProvider from '../components/NotistackProvider';
import ProgressBar from '../components/ProgressBar';
import ThemeColorPresets from '../components/ThemeColorPresets';
import ThemeLocalization from '../components/ThemeLocalization';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';
import Settings from '../components/settings';

import { Fab } from '@mui/material';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { AuthProvider } from '../contexts/FirebaseContext';
// ----------------------------------------------------------------------
MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  settings: PropTypes.object,
};

export default function MyApp(props) {
  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'AW-838970499');
    gtag('event', 'conversion', { 'send_to': 'AW-879926471/BTTECPzR-MsBEMe5yqMD' });
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-838970499" strategy="lazyOnload" />
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2522844423315072"
        strategy="lazyOnload"
        crossOrigin="anonymous"
      />

      <AuthProvider>
        <ReduxProvider store={store}>
          {/* <PersistGate loading={null} persistor={persistor} > */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CollapseDrawerProvider>
              <SettingsProvider defaultSettings={settings}>
                <ThemeProvider>
                  <NotistackProvider>
                    <MotionLazyContainer>
                      <ThemeColorPresets>
                        <ThemeLocalization>
                          <Settings />
                          <ProgressBar />
                          <LiveChatLoaderProvider
                            provider="messenger"
                            providerKey="104856525945193"
                            appID="632228391747595"
                            locale="vi_VN">
                            {getLayout(<Component {...pageProps} />)}
                          </LiveChatLoaderProvider>
                          <Fab variant='extended' size="medium" color="primary" sx={{ position: 'fixed', left: 20, bottom: 30 }}
                            onClick={() => window.location.href = "tel:0987072219"}>
                            <Icon icon="material-symbols:call" sx={{ mr: 1 }} />
                            098.707.2219
                          </Fab>
                        </ThemeLocalization>
                      </ThemeColorPresets>
                    </MotionLazyContainer>
                  </NotistackProvider>
                </ThemeProvider>
              </SettingsProvider>
            </CollapseDrawerProvider>
          </LocalizationProvider>
          {/* </PersistGate> */}
        </ReduxProvider>
      </AuthProvider >
    </>
  );
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie);

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
