import { createSlice } from '@reduxjs/toolkit';
// utils

const initialState = {
  answers: [
  ],
  answerFaileds: [
  ],
  isAnswered: false,
  hint: false
};

const slice = createSlice({
  name: 'gplxSituation',
  initialState,
  reducers: {
    addSituationAnswer(state, action) {
      const answer = action.payload;

      const answers = state.answers.filter(item => item.id !== answer.id);
      answers.push(answer);
      answers.sort((a, b) => a.orderNo - b.orderNo);

      state.answers = answers;
    },
    addSituationAnswerFailed(state, action) {
      const answer = action.payload;

      const answerFaileds = state.answerFaileds?.filter(item => item.id !== answer.id) || [];
      answerFaileds.push(answer);
      answerFaileds.sort((a, b) => a.orderNo - b.orderNo);

      state.answerFaileds = answerFaileds;
    },
    removeSituationAnswer(state, action) {
      const { id } = action.payload;

      const answers = state.answers.filter(item => item.id !== id);
      answers.sort((a, b) => a.orderNo - b.orderNo);

      state.answers = answers;
    },
    removeSituationAnswerFailed(state) {
      const answerFaileds = state.answerFaileds?.filter(item => item.score === 0);
      answerFaileds.sort((a, b) => a.orderNo - b.orderNo);

      state.answerFaileds = answerFaileds;
    },
    setAnswered(state, action) {
      state.isAnswered = action.payload;
    },
    setHint(state, action) {
      state.hint = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { addSituationAnswer, removeSituationAnswer, setAnswered, setHint, setShowCategories, addSituationAnswerFailed, removeSituationAnswerFailed } = slice.actions;
// ----------------------------------------------------------------------